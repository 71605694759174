import React, { Component } from "react"
import AOS from 'aos';
import Layout from "../components/layout"
import PortfolioMainBlocks from "../components/portfolioLayout"
import {Helmet} from 'react-helmet'
import Seo from "../components/seo"

class PortfolioPage extends Component {
  componentDidMount() {
    this.aos = AOS;
    this.aos.init();
  }    
  
  componentDidUpdate() {
    this.aos.refresh();
  }

   render () {
    return (
      <Layout>
        <Seo lang="en" />
        <Helmet>
          <title>My portfolio | SAHenry Portfolio</title>
          <meta name="description" content="This portfolio hosts 13 of my website and microsite builds over the last few years. Check out my previous design and development skills at work." />
          <link rel="canonical" href="https://sahenry.dev/my-portfolio/" />
          <meta property="og:locale" content="en_UK" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="My portfolio | SAHenry Portfolio" />
          <meta property="og:description" content="This portfolio hosts 13 of my website and microsite builds over the last few years. Check out my previous design and development skills at work." />
          <meta property="og:url" content="https://sahenry.dev/my-portfolio/" />
          <meta property="og:site_name" content="SAHenry Portfolio" />
          <meta property="og:image" content="/sahenry-portfolio_image_fbli.jpg" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Portfolio" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="My portfolio | SAHenry Portfolio" />
          <meta name="twitter:description" content="This portfolio hosts 13 of my website and microsite builds over the last few years. Check out my previous design and development skills at work." />
          <meta name="twitter:image" content="/sahenry-portfolio_image_fbli.jpg" />
        </Helmet>
        <PortfolioMainBlocks></PortfolioMainBlocks>	
      </Layout>
    )
   }
}


export default PortfolioPage
