import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import {GatsbyImage, getImage}  from "gatsby-plugin-image"
import PortMenu from "../components/portMenu";

export default function PortfolioMainBlocks() {
      const modalToggle = (e) => {
      const isBrowser = typeof document !== "undefined"
      if (isBrowser) {
        let dataatt = e.currentTarget.getAttribute("data-modal");
        let modalatt = document.getElementById(dataatt);      
        let modalidb = modalatt.getAttribute("id");
        e.preventDefault();
        if (dataatt === modalidb) {
          document.getElementById(dataatt).classList.add("open");
          document.getElementsByTagName("body")[0].style.overflow = "hidden";
        }
      }
      
    };
 
    
    const modalExit = (e) => {      
      const isBrowser = typeof document !== "undefined"
      if (isBrowser) {
        let datamatt = e.currentTarget.getAttribute("data-modalclose");
        let modaleatt = document.getElementById(datamatt);  
        let modalidb = modaleatt.getAttribute("id");
        e.preventDefault();
        if (datamatt === modalidb && modaleatt.classList.contains("modal")) {
          modaleatt.classList.remove("open");
          document.getElementsByTagName("body")[0].style.overflow = "auto"; 
        }
      }
      
    };

    


    
  
    const data = useStaticQuery(graphql`
      {
        allWpGportfolio {
          edges {
            node {
              portfolioPage {
                portfolioLayout {
                  ... on WpGportfolio_Portfoliopage_PortfolioLayout_MainBlocks {
                    mainSites {
                      desktopImage {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              blurredOptions: {width: 100}
                              backgroundColor: "#deb1b9"
                            )
                          }
                        }
                      }
                      ctaText
                      desktopAlt
                      imacImage {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              tracedSVGOptions: {alphaMax: 1.5}
                              blurredOptions: {width: 100}
                              layout: FULL_WIDTH
                            )
                          }
                        }
                      }
                      phoneAlt
                      phoneImage {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              tracedSVGOptions: {alphaMax: 1.5}
                              blurredOptions: {width: 100}
                              layout: CONSTRAINED
                              transformOptions: {fit: CONTAIN}
                            )
                          }
                        }
                      }
                      projectDescription
                      projectTags
                      projectUrl
                      sectionAos
                      sectionClass
                      sectionId
                      subtitle
                      textBlockHeading
                      titleMain
                    }
                  }
                  ... on WpGportfolio_Portfoliopage_PortfolioLayout_ModalAside {
                    fieldGroupName
                    modalRepeater {
                      blockId
                      ctaText
                      desktopAlt
                      modalId
                      phoneAlt
                      projectDescription
                      projectTags
                      projectUrl
                      subtitle
                      textBlockHeading
                      titleMain
                      desktopImage {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              blurredOptions: {width: 100}
                              backgroundColor: "#deb1b9"
                            )
                          }
                        }
                      }
                      imacImage {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              tracedSVGOptions: {alphaMax: 1.5}
                              layout: FULL_WIDTH
                              blurredOptions: {width: 100}
                            )
                          }
                        }
                      }
                      phoneImage {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              layout: CONSTRAINED
                              tracedSVGOptions: {alphaMax: 1.5}
                              transformOptions: {fit: CONTAIN}
                              blurredOptions: {width: 100}
                            )
                          }
                        }
                      }
                    }
                  }
                  ... on WpGportfolio_Portfoliopage_PortfolioLayout_GridBlock {
                    sectionAos
                    portfolioLink {
                      blockImage {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                      blockImageAlt
                      blockTitle
                      dataModal
                    }
                    sectionClasses
                    sectionId
                    sectionTitleFirst
                    sectionTitleSecond
                  }
                }
              }
            }
          }
        }
      }
      `)
      return (
        <div className="port-wrapper">
                 <div className="portfolio-page" >
                 {data.allWpGportfolio.edges[0].node.portfolioPage.portfolioLayout[0].mainSites.map(node => (
                    <section key={node.sectionId} id={node.sectionId} className="portfolio-block" data-menuanchor="page-one"  data-aos="portfolio-anim" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="false" data-aos-anchor-placement="top-center" data-aos-mirror="false">
                        <div className="portfolio-wrap">
                            <div className="title-wrapper">
                                <h2 className="title"><span>{node.titleMain}</span><span>{node.subtitle}</span></h2>
                            </div>
                            <div className="phone">
                                <GatsbyImage image={getImage(node.phoneImage.localFile.childImageSharp.gatsbyImageData)} alt={node.phoneAlt} src={node.phoneImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
                            </div>
                            <div className="text-block">
                                <h3 className="heading">{node.textBlockHeading}</h3>
                                <p className="copy">{node.projectDescription}</p>
                                <div className="tags"  dangerouslySetInnerHTML={{__html: node.projectTags}}></div>
                                <a href={node.projectUrl} className="cta">{node.ctaText}</a>
                            </div>
                            <div className="desktop-image" data-desktopimg={node.desktopImage.mediaItemUrl}>
                                <div className="mobile-casing">
                                    <GatsbyImage as="span" image={getImage(node.imacImage.localFile.childImageSharp.gatsbyImageData)} alt="imac casing" src={node.imacImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
                                      
                                </div>


                                  
                                  <GatsbyImage image={getImage(node.desktopImage.localFile.childImageSharp.gatsbyImageData)} alt={node.desktopAlt} src={node.desktopImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
                                    
                            </div>
                            <div className="desktop-background">&nbsp;</div>
                        </div>
                    </section>
                ))}
                <section id={data.allWpGportfolio.edges[0].node.portfolioPage.portfolioLayout[1].sectionId} className={data.allWpGportfolio.edges[0].node.portfolioPage.portfolioLayout[1].sectionClasses} data-menuanchor="page-four" data-aos="portfolio-anim"  data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="false" data-aos-anchor-placement="top-center" data-aos-mirror="false">        
                    <div className="port-grid-wrap">
                        <div className="port-grid">
                        <h2 className="title"><span>{data.allWpGportfolio.edges[0].node.portfolioPage.portfolioLayout[1].sectionTitleFirst}</span>&nbsp;<span>{data.allWpGportfolio.edges[0].node.portfolioPage.portfolioLayout[1].sectionTitleSecond}</span></h2>
                        {data.allWpGportfolio.edges[0].node.portfolioPage.portfolioLayout[1].portfolioLink.map(node => (
                            <a href="#modalover" className="port-link" key={node.blockTitle} data-modal={node.dataModal} data-stitle={node.blockTitle} onClick={modalToggle}>
                            <div className="dirt"></div>
                            <div className="dirr"></div>
                            <div className="dirb"></div>
                            <div className="dirl"></div>
                            <GatsbyImage image={getImage(node.blockImage.localFile.childImageSharp.gatsbyImageData)} alt={node.blockImageAlt} src={node.blockImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />                            
                            <div className="port-overlay"><span className="overlay-blend"></span></div>
                            <div className="block-title"><p>{node.blockTitle}</p></div>
                            
                        </a>
                        ))}
                        </div>            
                    </div>
                
                    
                </section>      
                </div>
                <PortMenu></PortMenu>
                <aside className="modal-block">
        {data.allWpGportfolio.edges[0].node.portfolioPage.portfolioLayout[3].modalRepeater.map(node => (
            <div className={`modal ${node.modalId}`} key={node.blockId} id={node.modalId}>
            <div className="modal-bg modal-exit"></div>
            <div className="modal-container">
              <section className="modal-content">
                      <section id={node.modalId} className={`portfolio-block ${node.modalId}`}>
                          <div className="portfolio-wrap">
                              <div className="title-wrapper">
                                  <h2 className="title"><span>{node.titleMain}</span><span>{node.subtitle}</span></h2>
                              </div>
                              <div className="phone">
                                <GatsbyImage image={getImage(node.phoneImage.localFile.childImageSharp.gatsbyImageData)} alt={node.phoneAlt} src={node.phoneImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
                              </div>
                              <div className="text-block">
                                  <h3 className="heading">{node.textBlockHeading}</h3>
                                  <p className="copy">{node.projectDescription}</p>
                                  <div className="tags" dangerouslySetInnerHTML={{__html: node.projectTags}}></div>
                                  <a href={node.projectUrl} target="_blank" rel="noopener noreferrer" className="cta">{node.ctaText}</a>
                              </div>
                              <div className="desktop-image" data-desktopimg={node.desktopImage.mediaItemUrl}>
                                <div className="mobile-casing">
                                    <GatsbyImage as="span" image={getImage(node.imacImage.localFile.childImageSharp.gatsbyImageData)} alt="imac casing" src={node.imacImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
                                </div>
                                
                                <GatsbyImage image={getImage(node.desktopImage.localFile.childImageSharp.gatsbyImageData)} alt={node.desktopAlt} src={node.desktopImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
                                
                              </div>
                              <div className="desktop-background">&nbsp;</div>
                          </div>
                      </section>
                      <button className="modal-close modal-exit" type="button" aria-label="Close modal" data-modalclose={node.modalId} onClick={modalExit}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><defs><style>{`.mci-1{fill:#222;}.mci-2{fill:#607d86;}`}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="background"><circle className="mci-1" cx="100" cy="100" r="100"/></g><g id="cross"><polygon className="mci-2" points="146.94 39.65 100 86.59 53.06 39.65 39.64 53.06 86.59 100 39.64 146.94 53.06 160.35 100 113.41 146.94 160.35 160.36 146.94 113.41 100 160.36 53.06 146.94 39.65"/></g></g></svg></button>
              </section>
              
                  
            </div>
            </div>
        ))}         
        </aside>
            </div>
      )
  
  
  
  }



