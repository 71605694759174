import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const PortMenu = () => (
    <StaticQuery query={graphql`
	{
        allWpGportfolio {
          edges {
            node {
              portfolioPage {
                portfolioLayout {
                  ... on WpGportfolio_Portfoliopage_PortfolioLayout_StickyMenu {
                    fieldGroupName
                    linkRepeater {
                      aosSettings
                      linkHref
                      linkIcon
                      linkTitle
                    }
                  }
                }
              }
            }
          }
        }
      } 

	`} render={ props => (
        <div className="menu-wrap">
            <ul id="portMenu">
                {props.allWpGportfolio.edges[0].node.portfolioPage.portfolioLayout[2].linkRepeater.map(node => (
                <li key={node.linkTitle}>
                    <AnchorLink to={node.linkHref} title={node.linkTitle} data-aos="side-menu-anim" data-aos-anchor={node.linkHref} data-aos-mirror="true" offset="100">
                    <span dangerouslySetInnerHTML={{__html: node.linkIcon}}></span><span>{node.linkTitle}</span>
                    </AnchorLink>
                </li>
                ))}
                </ul>            
            </div>		
	)} />
)

export default PortMenu;

